// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("/Users/jose.iniguez/Development/React/quinta-lili/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("/Users/jose.iniguez/Development/React/quinta-lili/src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-amenities-js": () => import("/Users/jose.iniguez/Development/React/quinta-lili/src/pages/amenities.js" /* webpackChunkName: "component---src-pages-amenities-js" */),
  "component---src-pages-biercito-brew-js": () => import("/Users/jose.iniguez/Development/React/quinta-lili/src/pages/biercito_brew.js" /* webpackChunkName: "component---src-pages-biercito-brew-js" */),
  "component---src-pages-contact-js": () => import("/Users/jose.iniguez/Development/React/quinta-lili/src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-facilities-js": () => import("/Users/jose.iniguez/Development/React/quinta-lili/src/pages/facilities.js" /* webpackChunkName: "component---src-pages-facilities-js" */),
  "component---src-pages-index-js": () => import("/Users/jose.iniguez/Development/React/quinta-lili/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-reserve-js": () => import("/Users/jose.iniguez/Development/React/quinta-lili/src/pages/reserve.js" /* webpackChunkName: "component---src-pages-reserve-js" */),
  "component---src-pages-rooms-js": () => import("/Users/jose.iniguez/Development/React/quinta-lili/src/pages/rooms.js" /* webpackChunkName: "component---src-pages-rooms-js" */),
  "component---src-pages-rooms-blueview-jsx": () => import("/Users/jose.iniguez/Development/React/quinta-lili/src/pages/rooms/blueview.jsx" /* webpackChunkName: "component---src-pages-rooms-blueview-jsx" */),
  "component---src-pages-rooms-familysuite-jsx": () => import("/Users/jose.iniguez/Development/React/quinta-lili/src/pages/rooms/familysuite.jsx" /* webpackChunkName: "component---src-pages-rooms-familysuite-jsx" */),
  "component---src-pages-rooms-juniorroom-jsx": () => import("/Users/jose.iniguez/Development/React/quinta-lili/src/pages/rooms/juniorroom.jsx" /* webpackChunkName: "component---src-pages-rooms-juniorroom-jsx" */),
  "component---src-pages-rooms-mastersuite-jsx": () => import("/Users/jose.iniguez/Development/React/quinta-lili/src/pages/rooms/mastersuite.jsx" /* webpackChunkName: "component---src-pages-rooms-mastersuite-jsx" */),
  "component---src-pages-rooms-redsunset-jsx": () => import("/Users/jose.iniguez/Development/React/quinta-lili/src/pages/rooms/redsunset.jsx" /* webpackChunkName: "component---src-pages-rooms-redsunset-jsx" */),
  "component---src-pages-rooms-roofgarden-jsx": () => import("/Users/jose.iniguez/Development/React/quinta-lili/src/pages/rooms/roofgarden.jsx" /* webpackChunkName: "component---src-pages-rooms-roofgarden-jsx" */),
  "component---src-pages-services-js": () => import("/Users/jose.iniguez/Development/React/quinta-lili/src/pages/services.js" /* webpackChunkName: "component---src-pages-services-js" */),
  "component---src-pages-tequesquitengo-js": () => import("/Users/jose.iniguez/Development/React/quinta-lili/src/pages/tequesquitengo.js" /* webpackChunkName: "component---src-pages-tequesquitengo-js" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/Users/jose.iniguez/Development/React/quinta-lili/.cache/data.json")

